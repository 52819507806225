import React, { Component } from 'react';
import FullPageLoader from '../throbber/loader';
import AccountManager from '../../managers/Account';
import { getParameterByName } from '../../managers/Helpers';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    // check for a nft token.

    const unauthPath = '/login';
    if (getParameterByName('id', window.location.href)) {
      // protec against duplicate tokens
      Object.entries(localStorage).map((obj) => {
        const key = obj[0];
        if (key.includes('NFT_TRANSFER')) {
          sessionStorage.removeItem(key);
        }
        return true;
      });
      // force the one in the URL to be in memory
      sessionStorage.setItem('NFT_TRANSFER_' + getParameterByName('id', window.location.href), getParameterByName('id', window.location.href));
      window.location.href = '/claim/?id=' + getParameterByName('id', window.location.href);
    }
    // check if the user is logged in. If they are, grab the account details
    if (AccountManager.isLoggedIn()) {
      window.location.pathname = '/account/collection';
    } else if (!getParameterByName('id', window.location.href)) {
      window.location.pathname = unauthPath;
    }
  }

  render() {
    return (
      <div>
        <FullPageLoader />
      </div>
    );
  }
}

export default Auth;
