/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function AssetFAQ({ creatorId, noHeader }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
    console.log('toggle answer');
    console.log(e);
    console.log(id);
  };

  const faq = [
    {
      id: 1,
      title: 'How do I log in?',
      answer: 'Your email will contain a magic link for you to click through and access your membership.',
    },
    {
      id: 2,
      title: 'How do I access my benefits?',
      answer: 'Once you’re logged in, click on your membership and your benefits will appear. Scroll through to see videos, access your exclusive discounts, and more.',
    },
    {
      id: 3,
      title: 'Can I add this to my Apple Wallet?',
      answer: 'Yes, absolutely! Simply view your membership page on your iPhone can click the “add to wallet” button on the top right of the page.',
    },
    {
      id: 4,
      title: 'How will I get notified when new benefits are available?',
      answer: 'We\'ll make sure to keep you in the loop and notify you as soon as new benefits become available through email.',
    },
    {
      id: 5,
      title: 'Can I invite others to join?',
      answer: 'You’re welcome to invite your friends to join our loyalty program and share the fantastic benefits!',
    },
    {
      id: 6,
      title: 'Do I have to pay for the membership?',
      answer: 'To join is free, however we do offer a Creator tier that offers additional benefits.',
    },
    {
      id: 7,
      title: 'Have a question?',
      answer: 'Contact our support team – <a href="mailto:info@insoundacademy.com">info@insoundacademy.com</a>',
    },
  ];

  useEffect(() => {
    setQuestions(faq);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader">Frequently Asked Questions</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default AssetFAQ;
