import React, { useState, useEffect } from 'react';
import Announcements from './announcements';

function CollectionHero({
  brandId, filterOptions, onFilterSelect,
}) {
  // State variables using useState
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const toggleTabs = (filterType, filterLabel, e) => {
    e.preventDefault();
    setTab(filterType);
    onFilterSelect(filterType, filterLabel);
  };

  useEffect(() => {
    if (filterOptions.length > 0) {
      setIsLoading(false);
    }
  }, [filterOptions]);

  return (
    <div
        className="collection-hero"
        style={{
          backgroundColor: process.env.BRAND_COLOR,
        }}>
      <div className="collection-hero__content">
        <h1 className="collection-hero__header">{process.env.SITE_NAME}</h1>
        <div className="collection-hero__nav">
          {!isLoading
          && (
          <div className="collection-hero__nav--inner">
            <div className="collection-hero__nav-item">
              <a href="#" onClick={(e) => toggleTabs(0, 'Collection', e)} className={tab === 0 ? '-selected' : ''}>Collection</a>
            </div>
            {filterOptions && filterOptions.length > 0
              && filterOptions.map((filterOption) => (
                <div key={filterOption.id} className="collection-hero__nav-item">
                  <a href="#" onClick={(e) => toggleTabs(filterOption.id, filterOption.label, e)} className={tab === filterOption.id ? '-selected' : ''}>{filterOption.label}</a>
                </div>
              ))}
          </div>
          )}
        </div>

        <Announcements creatorId={process.env.CREATOR_ID} />
      </div>
      <div className="collection-hero__gradient"></div>
    </div>
  );
}

export default CollectionHero;
