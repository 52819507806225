import React, { useState, useEffect } from 'react';

const HowItWorks = ({ asset }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const instructions = [
    {
      id: 1,
      title: 'Join',
      description: 'By entering your email, you’re a member! We’re happy to offer you benefits to turbocharge opportunities in music.',
    },
    {
      id: 2,
      title: 'Check your email',
      description: 'We’ll send you what is called a magic link where click through to login and explore your membership.',
    },
    {
      id: 3,
      title: 'Access Perks',
      description: 'Enjoy benefits like members only content, behind-the-scenes videos, exclusive offers, and event invites. Our membership is designed to bring the best offers to you in one place and connect you with the music community.',
    },
  ];

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div
      className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        {/* <img src="https://ipfs.ethosnft.com/insoundacademy/circle-how-it-works.png" alt="How it works" className="how-it-works__icon" /> */}
        <div className="how-it-works__title">
          How it works
        </div>
        <div className="how-it-works__list">
          {instructions.map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              {/* <div className="how-it-works__number">{index + 1}</div> */}
              <div className="how-it-works__step-title">{step.title}</div>
              <div className="how-it-works__step-description">{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
